"use client";

import { ComponentProps, useCallback, useEffect, useRef } from "react";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";

import {
  MediaPlayer,
  type MediaPlayerInstance,
  MediaProvider,
  Poster,
  useMediaRemote,
  Track,
  useMediaState,
} from "@vidstack/react";
import { cn } from "@/lib/utils";
// TODO: custom layout
import {
  defaultLayoutIcons,
  DefaultLayoutTranslations,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import useUpdateItemProgress from "@/courses/learn/hooks/useUpdateItemProgress";
import { useDebouncedCallback } from "use-debounce";
import VideoPlayerNextItem from "@/components/video-player-next-item";

const TURKISH_TRANSLATIONS: DefaultLayoutTranslations = {
  "Caption Styles": "Altyazı Stilleri",
  "Captions look like this": "Altyazılar böyle görünür",
  "Display Background": "Arka Planı Göster",
  "Keyboard Animations": "Klavye Animasyonları",
  "Text Background": "Metin Arka Planı",
  Accessibility: "Erişilebilirlik",
  Announcements: "Duyurular",
  Boost: "Sesi Yükselt",
  Color: "Renk",
  Disabled: "Devre Dışı",
  Download: "İndir",
  Family: "Aile",
  Font: "Yazı Tipi",
  Loop: "Döngü",
  Opacity: "Opaklık",
  Playback: "Oynatma",
  Shadow: "Gölge",
  Size: "Boyut",
  Text: "Metin",
  Track: "İz",
  "Google Cast": "Google Cast",
  AirPlay: "AirPlay",
  Connected: "Bağlandı",
  Connecting: "Bağlanıyor",
  Continue: "Devam",
  Disconnected: "Bağlantı Kesildi",
  Replay: "Tekrar Oynat",
  Reset: "Sıfırla",
  "Closed-Captions Off": "Altyazıları Kapat",
  "Closed-Captions On": "Altyazıları Aç",
  "Enter Fullscreen": "Tam Ekran",
  PiP: "Picture-in-Picture",
  "Enter PiP": "Picture-in-Picture",
  Fullscreen: "Tam Ekran",
  "Exit Fullscreen": "Tam Ekrandan Çık",
  "Exit PiP": "PiP Modundan Çık",
  "Seek Backward": "Geri Sar",
  "Seek Forward": "İleri Sar",
  "Skip To Live": "Canlıya Git",
  Audio: "Ses",
  Auto: "Otomatik",
  Captions: "Altyazılar",
  Chapters: "Bölümler",
  Default: "Varsayılan",
  LIVE: "CANLI",
  Mute: "Sessize Al",
  Normal: "Normal",
  Off: "Kapalı",
  Pause: "Duraklat",
  Play: "Oynat",
  Quality: "Kalite",
  Seek: "Atla",
  Settings: "Ayarlar",
  Speed: "Oynatma Hızı",
  Unmute: "Sesi Aç",
  Volume: "Ses",
};

interface Props {
  title?: string;
  src: string;
  chapters?: string;
  thumbnail?: {
    url: string;
    alt?: string | null;
  };
  autoPlay?: boolean;
  thumbnails?: string;
  className?: string;
  /**
   * The initial progress of the video between 0 and 1.
   */
  initialProgress?: number;
  itemId?: string;
  mode?: "learn" | "admin" | "store";
  muted?: boolean;
  jumpTo?: number;
}

const VideoPlayer: React.FC<Props> = ({
  title,
  src,
  chapters,
  thumbnail,
  thumbnails,
  className,
  itemId,
  autoPlay,
  initialProgress = 0,
  mode,
  muted = false,
  jumpTo,
}) => {
  const playerRef = useRef<MediaPlayerInstance | null>(null);
  const remote = useMediaRemote(playerRef.current);

  const canPlay = useMediaState("canPlay", playerRef);

  const [updateItemProgress, isUpdateItemProgressInFlight] =
    useUpdateItemProgress();

  const debouncedUpdateItemProgress = useDebouncedCallback(
    // function
    (currentTime: number, duration: number, _itemId: string) => {
      const progress = Math.min(currentTime / duration, 1);

      updateItemProgress({
        variables: {
          input: {
            itemId: _itemId,
            progress: progress,
            completed: progress > 0.99 ? true : undefined,
          },
        },
      });
    },
    // delay in ms
    3000,
    {},
  );

  useEffect(() => {
    // Subscribe to state updates.
    return playerRef.current!.subscribe(({ paused }) => {});
  }, [mode, itemId, updateItemProgress]);

  const onMetadataLoaded = useCallback<
    NonNullable<ComponentProps<typeof MediaPlayer>["onLoadedMetadata"]>
  >(
    (e) => {
      const time = initialProgress * playerRef.current!.state.duration;

      const jumpToTime = jumpTo ?? time;

      remote.seeking(jumpToTime, e);
      remote.seek(jumpToTime, e);

      if (itemId && mode === "learn") {
        debouncedUpdateItemProgress(
          jumpToTime,
          playerRef.current!.state.duration,
          itemId,
        );
      }
    },
    [
      remote,
      itemId,
      jumpTo,
      mode,
      debouncedUpdateItemProgress,
      initialProgress,
    ],
  );

  const onEnded = useCallback<
    NonNullable<ComponentProps<typeof MediaPlayer>["onEnded"]>
  >(
    (e) => {
      if (itemId && mode === "learn") {
        debouncedUpdateItemProgress.cancel();

        updateItemProgress({
          variables: {
            input: {
              itemId: itemId!,
              progress: 1.0,
              completed: true,
            },
          },
        });
      }
    },
    [mode, updateItemProgress, debouncedUpdateItemProgress, itemId],
  );

  const onPause = useCallback<
    NonNullable<ComponentProps<typeof MediaPlayer>["onPause"]>
  >(
    (e) => {
      if (itemId && mode === "learn") {
        debouncedUpdateItemProgress(
          playerRef.current!.state.currentTime,
          playerRef.current!.state.duration,
          itemId,
        );
      }
    },
    [itemId, mode, debouncedUpdateItemProgress],
  );

  const onTimeUpdate = useCallback<
    NonNullable<ComponentProps<typeof MediaPlayer>["onTimeUpdate"]>
  >(
    (e) => {
      if (itemId && mode === "learn") {
        debouncedUpdateItemProgress(
          playerRef.current!.state.currentTime,
          playerRef.current!.state.duration,
          itemId,
        );
      }
    },
    [itemId, mode, debouncedUpdateItemProgress],
  );

  useEffect(() => {
    if (canPlay && jumpTo !== undefined) {
      remote.seeking(jumpTo);
      remote.seek(jumpTo);
    }
  }, [remote, canPlay, jumpTo]);

  return (
    <MediaPlayer
      title={title}
      load={mode === "learn" ? "eager" : "idle"}
      src={src}
      className={cn(
        "ring-media-focus aspect-video w-full [&_iframe]:max-h-full [&_video]:max-h-full [&_iframe]:h-[var(--media-height)] [&_video]:h-[var(--media-height)] overflow-hidden bg-slate-900 font-sans text-white data-[focus]:ring-4",
        className,
      )}
      style={{
        "--video-border-radius": "0",
      }}
      ref={playerRef}
      crossOrigin="use-credentials"
      autoPlay={autoPlay}
      onLoadedMetadata={onMetadataLoaded}
      onPause={onPause}
      onEnded={onEnded}
      onTimeUpdate={onTimeUpdate}
      playsInline
      muted={muted}
    >
      <MediaProvider>
        {thumbnail && (
          <Poster
            className="absolute inset-0 block size-full object-cover opacity-0 transition-opacity data-[visible]:opacity-100"
            src={thumbnail.url}
            alt={thumbnail.alt || ""}
          />
        )}
        {chapters && (
          <Track
            src={chapters}
            kind="chapters"
            lang="tr-TR"
            type="vtt"
            default
          />
        )}
      </MediaProvider>
      <DefaultVideoLayout
        thumbnails={thumbnails}
        icons={defaultLayoutIcons}
        hideQualityBitrate
        noScrubGesture={false}
        className="rounded-none"
        translations={TURKISH_TRANSLATIONS}
      />
      {mode === "learn" && <VideoPlayerNextItem />}
    </MediaPlayer>
  );
};

export default VideoPlayer;
